import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const GenericButton = (props) => {
  const {
    text = '[Replace this text]',
    color = '#1B7392',
    backgroundColor = '#FFFFFF',
    borderColor = '#1B7392',
    centerInParent = true,
    width,
    height = '0px',
    borderRadius = '34px',
    minHeight = '0px',
    minWidth = '0px',
    maxHeight = '100%',
    maxWidth = '100%',
    paddingBottom = '0px',
    paddingLeft = '0px',
    paddingRight = '0px',
    paddingTop = '0px',
    fontSize = { xs:'18px', lg: '18px'},
    onClick
  } = props;

  return (
    <Box
      component="div"
      onClick={onClick}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        height: height,
        width: width,
        minHeight: minHeight,
        minWidth: minWidth,
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
        paddingLeft: paddingLeft, 
        paddingRight: paddingRight,
        background: backgroundColor,        // '#1B7392',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: borderRadius,
        border: `1px solid ${borderColor}`,
        margin: centerInParent ? '0 auto' : 'inherit'
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: fontSize,
          lineHeight: fontSize,
          color: color
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default GenericButton;