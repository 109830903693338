import React, { useState, useEffect } from 'react';
import _ from 'lodash-es';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import callApi from '../services/apiService';
import Layout from '../components/layout';
import PrimaryAppBar from '../components/appBar/PrimaryAppBar';

import ExpandableListItem from '../components/findYourBendable/ExpandableListItem';
import Footer from '../components/footer/Footer';


const FindYourBendablePage = (props) => {
  const { location } = props;
  console.log('ReserveMyPage location: ', location);

  // const [tenants, setTenants] = useState([]);
  const [groupings, setGroupings] = useState({});
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    const init = async () => {
      const apiResponse = await callApi('getTenants', null, { params: { controlling_instance: 'bendable-labs' } });
      const allTenants = apiResponse?.data?.payload?.tenants;
      console.log('sites.js useEffect allTenants: ', allTenants);
      if (allTenants) {
        // setTenants(allTenants);
        const newGroupings = {};
        _.forEach(allTenants, (tenant) => {
          if (tenant.groupings) {
            const newArray = _.isArray(newGroupings[tenant.groupings]) ? _.cloneDeep(newGroupings[tenant.groupings])  : [];      // make sure we have an array
            newArray.push(tenant);
            newGroupings[tenant.groupings] = _.sortBy(newArray, (item) => (item.display_name));
          }
        });
        console.log('sites.js useEffect newGroupings: ', newGroupings);
        setGroupings(newGroupings);
      }
    };
    init();
  }, []);

  useEffect(() => {
    const newHeadings = Object.keys(groupings);
    const sortedHeadings = newHeadings.sort();
    console.log('sites.js useEffect sortedHeadings: ', sortedHeadings);
    setHeadings(sortedHeadings);
  }, [groupings]);


  return (
    <Layout pageTitle="Find your Bendable">
      <PrimaryAppBar instanceName="" backPath="/" />

      <Box id="appBarCompensation" sx={{
        height: { xs: '58px', lg: '90px' },
      }} />

      <Box
        sx={{
          opacity: 99.9,
          // display: 'flex',
          // flexDirection: 'column',
          width: '100%',
          height: { xs:'80px', sm: '120px', md: '120px', lg: '160px' },
          // flexGrow: 1,
          background: "#95FFEF",
          paddingTop: { xs:'40px',sm: '40px', md: '40px', lg: '60px' },
          paddingBottom: { xs:'30px', lg: '30px' },
          paddingLeft: { xs:'26px', sm: '40px', md: '60px', lg: '100px' },
          paddingRight: { xs:'26px', lg: '80px' },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Cooper Std',
            fontStyle: 'normal',
            fontWeight: 900,
            fontSize: { xs:'24px', sm: '30px', md: '30px', lg: '54px' },
            lineHeight: { xs:'40px', lg: '60px' },
            letterSpacing: '-1.125px',
            color: '#1B7392',
            maxWidth: { xs:'100%', lg: '550px', xl: '1200px' },
            paddingBottom: { xs:'21px', lg: '60px' },
          }}
        >
          Find your Bendable
        </Typography>
      </Box>

      <Stack direction="column"
        sx={{
          marginBottom: { xs:'40px', lg: '100px' },
        }}
      >
        {
          headings.map((heading, index) => (
            <ExpandableListItem
              key={index}
              heading={heading}
              tenants={groupings[heading]}
            />

          ))
        }
      </Stack>

      <Footer />
    </Layout>
  )
}

export default FindYourBendablePage;