import React, { useState } from 'react';
// import _ from 'lodash-es';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import GenericButton from '../shared/genericButton';

const ExpandableListItem = (props) => {

  const {
    heading = '',
    tenants = [],
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded((prevState) => {
      const currentState = !prevState;
      return currentState;
    });
  };

  const handleTenantClick = (tenant) => {
    window.open(`https://${tenant.tenant}.bendable.com`, '_blank');
  };

  return (
    <Stack
      direction="column"
      sx={{
        marginLeft: { xs:'30px',  sm: '40px', md: '60px', lg: '100px' },
        marginRight: { xs:'10px', sm: '18px', md: '20px', lg: '200px', xl: '300px' },
        paddingBottom: { xs:'10px', lg: '20px' },
        borderBottom: '1px solid #000000',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs:'20px', sm: '28px', md: '36px', lg: '44px' },
          lineHeight: { xs:'40px', lg: '60px' },
          letterSpacing: '-1.125px',
          color: '#1B7392',
          maxWidth: { xs:'100%', lg: '550px', xl: '1200px' },
          paddingTop: { xs:'20px', lg: '40px' },
          paddingBottom: { xs:'10px', lg: '10px' },
        }}
      >
        {heading}
      </Typography>

      { isExpanded ?
        tenants.map((tenant, index) => (
          <Stack 
            key={`${heading}_${index}`}
            direction="row"
          >
            <Typography
              
              onClick={() => {handleTenantClick(tenant)}}
              sx={{
                fontFamily: 'Sharp Sans',
                fontSize: { xs: '16px', sm: '18px', md: '20px', lg: '24px', xl: '24px' },
                lineHeight: { xs: '20px', sm: '24px', md: '26px', lg: '30px', xl: '30px' },
                paddingBottom: { xs: '1px', lg: '10px' },
                color: '#000000',
                cursor: 'pointer',
              }}
            >
            {tenant.display_name}
            </Typography>
            <Box flexGrow={1} />
          </Stack>
        )) : null
      }

      <Stack direction="row">
        <Box flexGrow={1} />
        <Box>
          <GenericButton
            text={isExpanded ? 'Close' : 'Expand' }
            backgroundColor="#95FFEF"
            height={{ xs: '24px', sm: '28px', md: '34px', lg: '34px', xl: '40px' }}
            paddingTop={{ xs: '6px', sm: '8px', md: '10px', lg: '12px', xl: '14px' }}
            paddingBottom={{ xs: '6px', sm: '8px', md: '10px', lg: '12px', xl: '14px' }}
            paddingLeft={{ xs: '6px', sm: '8px', md: '10px', lg: '12px', xl: '14px' }}
            paddingRight={{ xs: '6px', sm: '8px', md: '10px', lg: '12px', xl: '14px' }}
            borderRadius={{ xs: '12px', sm: '18px', md: '20px', lg: '24px', xl: '24px' }}
            fontSize={{ xs: '16px', sm: '18px', md: '20px', lg: '24px', xl: '24px' }}
            onClick={toggleExpanded}
          />
        </Box>
      </Stack>

    </Stack>
  );
};

export default ExpandableListItem;


